import { template as template_e7d5d16f33c248868c504cb9f4dbec89 } from "@ember/template-compiler";
const FKText = template_e7d5d16f33c248868c504cb9f4dbec89(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
