import { template as template_5244858d543c4beaab3d5108d2aee014 } from "@ember/template-compiler";
import DButton from "discourse/components/d-button";
const BackButton = template_5244858d543c4beaab3d5108d2aee014(`
  <DButton
    @action={{@onGoBack}}
    @label="topic.timeline.back"
    @title="topic.timeline.back_description"
    class="btn-primary btn-small back-button"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default BackButton;
