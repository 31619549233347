import { template as template_8b413c614fc445ab97f5812582a9f3df } from "@ember/template-compiler";
const FKLabel = template_8b413c614fc445ab97f5812582a9f3df(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
