import { template as template_d60c06bb6121491baafd49942503fe2d } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const ViewsCell = template_d60c06bb6121491baafd49942503fe2d(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="views"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="views"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ViewsCell;
